import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  isCrmAuthenticated: false,
  domain: null,
  conversions: [],
}

export const fetchDomain = createAsyncThunk('fetchDomain', async () => {
  return await axios
    .get('crm/domain', {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
      throw error
    })
})

export const updateDomain = createAsyncThunk('updateDomain', async (data) => {
  const { domainId, json } = data
  return await axios
    .patch(`crm/domains/${domainId}`, json, {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      return error.response.data
    })
})

export const fetchConversions = createAsyncThunk(
  'fetchConversions',
  async () => {
    return await axios
      .get('crm', {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }
)

const crmAuthSlice = createSlice({
  name: 'crmAuth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isCrmAuthenticated = true
      state.domain = action.payload
    },
    logout: (state) => {
      state.isCrmAuthenticated = false
      state.domain = null
      state.conversions = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDomain.fulfilled, (state, action) => {
        state.domain = action.payload
      })
      .addCase(fetchConversions.fulfilled, (state, action) => {
        state.conversions = action.payload
      })
      .addCase(updateDomain.fulfilled, (state, action) => {
        if (!action.payload.hasOwnProperty('error'))
          state.domain = action.payload
      })
      .addCase(fetchDomain.rejected, (state) => {
        state.isCrmAuthenticated = false
      })
      .addCase(fetchConversions.rejected, (state) => {
        state.isCrmAuthenticated = false
      })
  },
})

export const { login, logout } = crmAuthSlice.actions
export const selectCrmAuth = (state) => state.crmAuth
export default crmAuthSlice.reducer
