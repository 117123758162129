import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  isAdminAuthenticated: false,
  admin: null,
  users: [],
  accounts: [],
  openAccountData: [],
  transactionUserData: [],
}

export const fetchAdmin = createAsyncThunk('fetchAdmin', async () => {
  return await axios
    .get('self-serve-ad/admins', {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const fetchUsers = createAsyncThunk('fetchUsers', async () => {
  return await axios
    .get('self-serve-ad/admins/users', {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const fetchAccounts = createAsyncThunk('fetchAccounts', async () => {
  return await axios
    .get('self-serve-ad/admins/accounts', {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const fetchOpenAccountData = createAsyncThunk(
  'fetchOpenAccountData',
  async () => {
    return await axios
      .get('self-serve-ad/admins/open-account', {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const updateOpenAccountData = createAsyncThunk(
  'updateOpenAccountData',
  async (json) => {
    return await axios
      .patch(`self-serve-ad/admins/open-account/${json.accountId}`, json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const updateTopupWalletTransaction = createAsyncThunk(
  'updateTopupWalletTransaction',
  async (json) => {
    return await axios
      .patch(`self-serve-ad/transactions/wallet/topup/${json.transactionId}`, json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const updateDeductWalletTransaction = createAsyncThunk(
  'updateDeductWalletTransaction',
  async (json) => {
    return await axios
      .patch(`self-serve-ad/transactions/wallet/deduct/${json.transactionId}`, json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const updateTopupAccountTransaction = createAsyncThunk(
  'updateTopupAccountTransaction',
  async (json) => {
    return await axios
      .patch(`self-serve-ad/transactions/account/topup/${json.transactionId}`, json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const updateDeductAccountTransaction = createAsyncThunk(
  'updateDeductAccountTransaction',
  async (json) => {
    return await axios
      .patch(`self-serve-ad/transactions/account/deduct/${json.transactionId}`, json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const fetchTransactionUserData = createAsyncThunk(
  'fetchTransactionUserData',
  async () => {
    return await axios
      .get('self-serve-ad/transactions/transaction-user', {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

const adminAuthSlice = createSlice({
  name: 'adminAuth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAdminAuthenticated = true
      state.admin = action.payload
    },
    logout: (state) => {
      state.isAdminAuthenticated = false
      state.admin = null
      state.users = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        state.admin = action.payload
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload
      })
      .addCase(fetchOpenAccountData.fulfilled, (state, action) => {
        state.openAccountData = action.payload
      })
      .addCase(fetchTransactionUserData.fulfilled, (state, action) => {
        state.transactionUserData = action.payload
      })
      .addCase(updateTopupWalletTransaction.fulfilled)
      .addCase(updateDeductWalletTransaction.fulfilled)
      .addCase(updateTopupAccountTransaction.fulfilled)
      .addCase(updateDeductAccountTransaction.fulfilled)
  },
})

export const { login, logout } = adminAuthSlice.actions
export const selectAdminAuth = (state) => state.adminAuth
export default adminAuthSlice.reducer
