import '../Main.css'
import OverviewTable from './table/OverviewTable'
import SideBar from './global/SideBar'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers } from '../../redux/adminAuthSlice'
import { useEffect, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'

export default function HomePage() {
  const { admin, users } = useSelector((state) => state.adminAuth)
  const [userId, setUserId] = useState('')
  const [companyCode, setCompanyCode] = useState('')
  const [editCompanyCodeFormOpen, setEditCompanyCodeFormOpen] = useState(false)
  const dispatch = useDispatch()

  const handlePatchUserCompanyCode = async (event) => {
    event.preventDefault()
    await axios
      .patch(
        `self-serve-ad/admins/users/${userId}`,
        { companyCode },
        {
          withCredentials: true,
        }
      )
      .then(async function (response) {
        if (response.status === 200) {
          setEditCompanyCodeFormOpen(false)
          dispatch(fetchUsers())
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleUserIdFromChild = (userId) => {
    setUserId(userId)
    setCompanyCode(users.find((user) => user._id === userId).companyCode)
    setEditCompanyCodeFormOpen(true)
  }

  useEffect(() => {
    dispatch(fetchUsers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar admin={admin} />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='ml-6 mt-6 w-full'>
          <div className='text-xl font-bold'>Overview</div>
        </section>
        <section className='ml-6 mt-4 w-11/12'>
          <OverviewTable
            users={
              Array.isArray(users) ? users.filter((user) => user.verified) : []
            }
            sendUserIdToParent={handleUserIdFromChild}
          />
          <Transition.Root show={editCompanyCodeFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setEditCompanyCodeFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <div>
                            <input
                              value={companyCode}
                              onChange={(e) => setCompanyCode(e.target.value)}
                              type='text'
                              className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='mt-4 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handlePatchUserCompanyCode}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </section>
      </div>
    </>
  )
}
