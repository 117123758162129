import { APPS, toastConfig } from './constants'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

export function toastErrorMessage(msg) {
  toast.error(msg, toastConfig)
}

export function toastSuccessMessage(msg) {
  toast.success(msg, toastConfig)
}

export function anyObjectKeyHasValue(object) {
  for (const key in object) {
    if (object[key]) return true
  }
  return false
}

export const getApp = () => {
  const getSubdomain = (location) => {
    const locationParts = location.split('.')
    let sliceTill = process.env.REACT_APP_ENV === 'development' ? -1 : -2

    return locationParts.slice(0, sliceTill).join('')
  }

  const subdomain = getSubdomain(window.location.hostname)
  const main = APPS.find((app) => app.main)
  if (!main) throw new Error('Must have main app')
  if (subdomain === '') return main.app
  const app = APPS.find((app) => subdomain === app.subdomain)
  if (!app) return main.app
  return app.app
}

export default function useWindowDimensions() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
