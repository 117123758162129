import useWindowDimensions from '../../utils/helpers'
import { useState } from 'react'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import promotional from '../../assets/promotional.png'
const validator = require('validator')

export default function RegisterPage() {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')

  const validatePassword = (password) => {
    // Add your own validation logic, for example:
    // - Must be at least 6 characters long
    // - Must include uppercase and lowercase letters
    // - Must include a number
    // - Must include a special character
    const minLength = password.length >= 6
    // const hasUpper = /[A-Z]/.test(password);
    // const hasLower = /[a-z]/.test(password);
    // const hasNumber = /[0-9]/.test(password);
    // const hasSpecial = /[@$!%*?&#]/.test(password);

    if (!minLength) {
      setPasswordError('Password must be at least 6 characters long')
      return false
    }
    setPasswordError('')
    return true
  }

  const validateEmail = (email) => {
    if (!validator.isEmail(email)) {
      setEmailError('Please enter valid email')
      return false
    }
    setEmailError('')
    return true
  }

  const handleNameChange = (event) => {
    const newName = event.target.value
    setName(newName)
  }

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value
    setPassword(newPassword)
    validatePassword(newPassword)
  }

  const handleEmailChange = (event) => {
    const newEmail = event.target.value
    setEmail(newEmail)
    validateEmail(newEmail)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validatePassword(password) && validateEmail(email)) {
      // Submit your validated password to your service
      console.log('Password is valid:', password)
      axios
        .post('self-serve-ad/users/signup', {
          name,
          password,
          email
        })
        .then(function (response) {
          if (response.data.message === 'Email sent, please check your email')
            navigate('/verification')
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  return (
    <div className='w-screen h-screen bg-gradient-to-tr from-registerPageBgColorFrom to-registerPageBgColorTo'>
      {/* wrapper 2 box */}
      <div className='w-full h-screen flex items-center justify-center'>
        {/* form */}
        <div className='bg-[#F8F7F4] w-full max-w-lg rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
              Create an account
            </h1>
            <form
              className='h-full mt-4 md:mt-6'
              action='#'
              onSubmit={handleSubmit}
            >
              <div className='mb-4'>
                <label
                  htmlFor='name'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Name
                </label>
                <input
                  type='text'
                  name='name'
                  onChange={handleNameChange}
                  id='name'
                  className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#aa076b] focus:border-primary-600 block w-full p-2.5'
                  placeholder='Enter your name'
                  required={true}
                />
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='email'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Email
                </label>
                <input
                  type='email'
                  name='email'
                  onChange={handleEmailChange}
                  id='email'
                  className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#aa076b] focus:border-primary-600 block w-full p-2.5'
                  placeholder='Enter your email'
                  required={true}
                />
                {emailError && (
                  <p className='mt-1 text-xs text-red-500'>
                    {emailError}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor='password'
                  className='block mb-2 text-sm font-medium text-gray-900'
                >
                  Password
                </label>
                <input
                  type='password'
                  name='password'
                  onChange={handlePasswordChange}
                  id='password'
                  placeholder='••••••••'
                  className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-[#aa076b] focus:border-primary-600 block w-full p-2.5'
                  required={true}
                />
                {passwordError && (
                  <p className='mt-1 text-xs text-red-500'>
                    {passwordError}
                  </p>
                )}
              </div>
              {/* <div>
                  <label
                    for='confirm-password'
                    className='block mb-2 text-sm font-medium text-gray-900'
                  >
                    Confirm password
                  </label>
                  <input
                  value={password}
            onChange={handlePasswordChange}
                    type='confirm-password'
                    name='confirm-password'
                    id='confirm-password'
                    placeholder='••••••••'
                    className='bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
                    required=''
                  />
                </div> */}
              {/* <div className='flex items-start'>
                  <div className='flex items-center h-5'>
                    <input
                      id='terms'
                      aria-describedby='terms'
                      type='checkbox'
                      className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300'
                      required=''
                    />
                  </div>
                  <div className='ml-3 text-sm'>
                    <label
                      for='terms'
                      className='font-light text-gray-500'
                    >
                      I accept the{' '}
                      <a
                        className='font-medium text-primary-600 hover:underline'
                        href='#'
                      >
                        Terms and Conditions
                      </a>
                    </label>
                  </div>
                </div> */}
              <button
                type='submit'
                className='mb-4 mt-7 w-full text-white bg-pink-800 hover:bg-pink-700 focus:ring-4 focus:outline-none focus:ring-pink-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
              >
                Create an account
              </button>
              <p className='text-sm font-light'>
                Already have an account?{' '}
                <Link
                  to={`${process.env.REACT_APP_SCHEME}://app.${process.env.REACT_APP_DOMAIN}/login`}
                  className='font-medium text-primary-600 hover:underline'
                >
                  Login here
                </Link>
              </p>
            </form>
          </div>
        </div>
        {width > 1010 && (
          <div className='w-full max-w-lg text-white'>
            <div className='text-center font-semibold text-lg mb-8'>
              Get a Stable Advertising Account now ! <br />
              So you can focus on developing your business
            </div>
            <div className='flex justify-center items-center'>
              <img alt='promotional' width={380} src={promotional} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
