import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import io from 'socket.io-client'
import QRCode from 'react-qr-code'
import { ToastContainer } from 'react-toastify'
import '../Main.css'
import SideBar from './global/SideBar'
import { fetchDomain } from '../../redux/crmAuthSlice'
import { Input } from '../../components/ui/input'
import { Label } from '../../components/ui/label'
import { Button } from '../../components/ui/button'
import { updateDomain } from '../../redux/crmAuthSlice'
import {
  toastErrorMessage,
  toastSuccessMessage,
  anyObjectKeyHasValue,
} from '../../utils/helpers'

export default function ConfigPage() {
  const dispatch = useDispatch()
  const { domain } = useSelector((state) => state.crmAuth)
  const [phoneNumberQrCode, setPhoneNumberQrCode] = useState([])

  // trigger words
  const [initiateCheckout, setInitiateCheckout] = useState('')
  const [addToCart, setAddToCart] = useState('')
  const [purchase, setPurchase] = useState('')

  // meta info
  const [pixelId, setPixelId] = useState(domain.meta.pixelId)
  const [accessToken, setAccessToken] = useState(domain.meta.accessToken)

  // phone numbers info
  const [phoneNumbers, setPhoneNumbers] = useState(domain.phoneNumbers)

  function updatePhoneNumber(index, newPhoneNumber) {
    const newPhoneNumbers = phoneNumbers.map((phoneNumber, i) =>
      i === index ? newPhoneNumber : phoneNumber
    )
    setPhoneNumbers(newPhoneNumbers)
  }

  function saveTriggerWords() {
    if (!initiateCheckout && !addToCart && !purchase) {
      toastErrorMessage('Please fill in at least 1')
      return
    }

    const anyValueIsNotUnique =
      (initiateCheckout &&
        (initiateCheckout === addToCart || initiateCheckout === purchase)) ||
      (addToCart &&
        (initiateCheckout === addToCart || addToCart === purchase)) ||
      (purchase && (initiateCheckout === purchase || addToCart === purchase))

    if (anyValueIsNotUnique) {
      toastErrorMessage('Values have to be unique')
      return
    }

    const json = { triggerWords: { initiateCheckout, addToCart, purchase } }
    const data = { domainId: domain._id, json }
    dispatch(updateDomain(data)).then(() =>
      toastSuccessMessage('Successful update trigger words')
    )
  }

  function saveMetaInfo() {
    const json = { meta: { pixelId, accessToken } }
    const data = { domainId: domain._id, json }
    dispatch(updateDomain(data)).then(() =>
      toastSuccessMessage('Successful update trigger words')
    )
  }

  async function savePhoneNumbers() {
    const json = { phoneNumbers }
    const data = { domainId: domain._id, json }

    dispatch(updateDomain(data)).then((resp) => {
      const { error } = resp?.payload
      if (error) toastErrorMessage(error)
      else toastSuccessMessage('Successful update phone numbers')
    })
  }

  function createSessionForWhatsapp(phoneNumber) {
    if (!anyObjectKeyHasValue(domain.triggerWords)) {
      toastErrorMessage(
        'Please ensure at leat 1 trigger word is filled at config page'
      )
      return false
    }
    const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`, {})
    socket.emit('createSession', phoneNumber)
    socket.on('qr', (data) => {
      console.log('QR RECEIVED')
      console.log(data)
      const { phoneNumber, qr } = data
      let phoneNumberQrCodeCopy = phoneNumberQrCode
      let newPhoneNumberQrCode = []
      for (let i = 0; i < phoneNumberQrCodeCopy.length; i++) {
        if (phoneNumberQrCodeCopy[i].phoneNumber === phoneNumber)
          phoneNumberQrCodeCopy[i].qr = qr
        newPhoneNumberQrCode.push(phoneNumberQrCodeCopy[i])
      }
      setPhoneNumberQrCode(newPhoneNumberQrCode)
      toastSuccessMessage('QR code generated')
    })
    socket.on('ready', (data) => {
      toastSuccessMessage('Whatsapp connected')
    })
  }

  useEffect(() => {
    dispatch(fetchDomain())
    setInitiateCheckout(domain.triggerWords.initiateCheckout)
    setAddToCart(domain.triggerWords.addToCart)
    setPurchase(domain.triggerWords.purchase)
    let data = []
    for (const phoneNumber of domain.phoneNumbers) {
      data.push({ phoneNumber, qr: '' })
    }
    setPhoneNumberQrCode(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar domain={domain} />
      <ToastContainer />
      <div className='home_content poppins bg-[#f1faff]'>
        <div className='mx-12 pt-8'>
          <div>
            <h2 className='mb-4'>Trigger Words</h2>
            <Label>Initiate checkout</Label>
            <Input
              className='w-[800px]'
              value={initiateCheckout}
              onChange={(e) => setInitiateCheckout(e.target.value)}
            />
            <Label>Add to cart</Label>
            <Input
              className='w-[800px]'
              value={addToCart}
              onChange={(e) => setAddToCart(e.target.value)}
            />
            <Label>Purchase</Label>
            <Input
              className='w-[800px]'
              value={purchase}
              onChange={(e) => setPurchase(e.target.value)}
            />
            <Button
              className='mt-4'
              onClick={saveTriggerWords}
              variant='outline'
            >
              Save
            </Button>
          </div>
        </div>
        <div className='mx-14 mt-12 flex'>
          <div>
            <h2 className='mb-4'>Meta</h2>
            <Label>Pixel ID</Label>
            <Input
              className='w-80'
              value={pixelId}
              onChange={(e) => setPixelId(e.target.value)}
            />
            <Label>Access Token</Label>
            <Input
              className='w-80'
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
            />
            <Button className='mt-4' onClick={saveMetaInfo} variant='outline'>
              Save
            </Button>
          </div>
          <div className='ml-8'>
            <h2 className='mb-10'>Phone Number</h2>
            {domain.phoneNumbers.map((item, index) => (
              <Input
                key={index}
                className='mb-4 w-80'
                value={phoneNumbers[index]}
                onChange={(e) => updatePhoneNumber(index, e.target.value)}
              />
            ))}
            <Button onClick={savePhoneNumbers} variant='outline'>
              Save
            </Button>
          </div>
          <div className='mx-12'>
            <div className='flex'>
              {phoneNumberQrCode.map((item, index) => (
                <div key={index} className='mr-12'>
                  <QRCode value={item.qr} />
                  <Button
                    className='mt-4'
                    onClick={(e) => createSessionForWhatsapp(item.phoneNumber)}
                    variant='outline'
                  >
                    {item.phoneNumber}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
