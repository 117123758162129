import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { getApp } from './utils/helpers'
import './App.css'
import Axios from 'axios'

Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
export default function App() {
  const CurrentApp = getApp()
  return (
    <>
      <BrowserRouter>
        <CurrentApp />
      </BrowserRouter>
    </>
  )
}
