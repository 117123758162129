import '../Main.css'
import OpenAccountTable from './table/OpenAccountTable'
import SideBar from './global/SideBar'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, Fragment, useState } from 'react'
import {
  fetchOpenAccountData,
  updateOpenAccountData,
} from '../../redux/adminAuthSlice'
import { Dialog, Transition } from '@headlessui/react'

export default function OpenAccountPage() {
  const [editStatusFormOpen, setEditStatusFormOpen] = useState(false)
  const [statusChangedTo, setStatusChangedTo] = useState('')
  const [rejectedReason, setRejectedReason] = useState('')
  const [adAccountId, setAdAccountId] = useState('')
  const [adAccountName, setAdAccountName] = useState('')
  const [showRejectedReasonTextArea, setShowRejectedReasonTextArea] =
    useState(false)
  const [showApprovedFields, setShowApprovedFields] = useState(false)
  const [isBmBcConnected, setIsBmBcConnected] = useState(false)
  const [selectedOpenAccountData, setSelectedOpenAccountData] = useState({})
  const dispatch = useDispatch()
  const { admin, openAccountData } = useSelector((state) => state.adminAuth)

  const handleAccountFromChild = (data) => {
    setAdAccountId('')
    setAdAccountName('')
    setIsBmBcConnected(false)
    setStatusChangedTo('Review')
    setSelectedOpenAccountData(data)
    setShowRejectedReasonTextArea(false)
    setShowApprovedFields(false)
    setEditStatusFormOpen(true)
  }

  function statusSelectionChanged(status) {
    if (status === 'Rejected') setShowRejectedReasonTextArea(true)
    else setShowRejectedReasonTextArea(false)
    if (status === 'Approved') setShowApprovedFields(true)
    else setShowApprovedFields(false)
    setStatusChangedTo(status)
  }

  async function handleEditStatus() {
    let json = {}

    if (statusChangedTo === 'Review') json.status = statusChangedTo
    if (statusChangedTo === 'Process') json.status = statusChangedTo

    if (statusChangedTo === 'Rejected') {
      if (!rejectedReason) return
      else {
        json.userName = selectedOpenAccountData.userName
        json.status = statusChangedTo
        json.rejectedReason = rejectedReason
      }
    }

    if (statusChangedTo === 'Approved') {
      if (!adAccountId || !adAccountName || !isBmBcConnected) return
      json.userName = selectedOpenAccountData.userName
      json.status = statusChangedTo
      json.id = adAccountId
      json.name = adAccountName
      json.isBmBcConnected = isBmBcConnected
    }

    json.accountId = selectedOpenAccountData.accountId
    setEditStatusFormOpen(false)
    dispatch(updateOpenAccountData(json)).then(() =>
      dispatch(fetchOpenAccountData())
    )
  }

  useEffect(() => {
    dispatch(fetchOpenAccountData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar admin={admin} />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='ml-6 mt-6 w-full'>
          <div className='text-xl font-bold'>Open Account</div>
        </section>
        <section className='ml-6 mt-4 w-11/12'>
          <OpenAccountTable
            openAccountData={openAccountData || []}
            sendDataToParent={handleAccountFromChild}
          />
          <Transition.Root show={editStatusFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setEditStatusFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div className='mb-4 text-center'>
                        <select
                          onChange={(e) =>
                            statusSelectionChanged(e.target.value)
                          }
                          className='h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                        >
                          <option>Review</option>
                          <option>Rejected</option>
                          <option>Process</option>
                          <option>Approved</option>
                        </select>
                      </div>

                      {showApprovedFields && (
                        <>
                          <label className='block text-center text-sm font-medium leading-6 text-gray-900'>
                            Ad Account Name
                          </label>
                          <div className='my-3'>
                            <input
                              onChange={(e) => setAdAccountName(e.target.value)}
                              type='text'
                              className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              placeholder='Enter Ad Account Name'
                            />
                          </div>

                          <label className='block text-center text-sm font-medium leading-6 text-gray-900'>
                            Ad Account Id
                          </label>
                          <div className='relative mt-2 rounded-md shadow-sm'>
                            <input
                              onChange={(e) => setAdAccountId(e.target.value)}
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'e' ||
                                  e.key === '+' ||
                                  e.key === '-' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              type='number'
                              className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              placeholder='Enter Ad Account Id'
                            />
                          </div>

                          <label className='mt-4 inline-flex cursor-pointer items-center'>
                            <span className='mr-2 ms-3 text-sm font-medium text-gray-900 dark:text-gray-300'>
                              Connect BM/BC and Personal Account
                            </span>
                            <input
                              type='checkbox'
                              onChange={(e) =>
                                setIsBmBcConnected(e.target.checked)
                              }
                              className='peer sr-only'
                            />
                            <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800 rtl:peer-checked:after:-translate-x-full"></div>
                          </label>
                        </>
                      )}

                      {showRejectedReasonTextArea && (
                        <div className='mt-2'>
                          <textarea
                            rows={8}
                            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            onChange={(e) => setRejectedReason(e.target.value)}
                          />
                        </div>
                      )}

                      <div className='mt-4 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-16 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleEditStatus}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </section>
      </div>
    </>
  )
}
