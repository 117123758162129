// copas from https://codesandbox.io/p/sandbox/responsive-dashboard-sidebar-menu-gi7zl?file=%2Findex.html%3A6%2C13-6%2C66
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import profilePicture from '../../../assets/profile-picture.svg'
import { logout } from '../../../redux/crmAuthSlice'

export default function SideBar(props) {
  const location = useLocation()
  const domain = props.domain
  const [isActive, setIsActive] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const toggleClass = () => {
    setIsActive(!isActive)
  }

  const handleLogout = async (event) => {
    event.preventDefault()
    dispatch(logout())
    navigate('/login')
  }
  return (
    <div className={isActive ? 'poppins sidebar active' : 'poppins sidebar'}>
      <div className='logo_content'>
        <div className='logo'>
          <div className='logo_name'>MXD Platform</div>
        </div>
        {/* <i onClick={toggleClass} className='bx bx-menu' id='btn'></i> */}
      </div>
      <ul className='nav_list'>
        <li>
          <Link
            className={location.pathname === '/' ? 'activePath' : ''}
            to='/'
          >
            <i className='bx bx-grid-alt'></i>
            <span className='links_name'>Overview</span>
          </Link>
          <span className='tooltip'>Overview</span>
        </li>
        <li>
          <Link
            to='/config'
            className={location.pathname === '/config' ? 'activePath' : ''}
          >
            <i className='bx bx-cog'></i>
            <span className='links_name'>Config</span>
          </Link>
          <span className='tooltip'>Config</span>
        </li>
        {/* <li>
          <Link
            to='/faq'
            className={location.pathname === '/faq' ? 'activePath' : ''}
          >
            <i className='bx bx-question-mark'></i>
            <span className='links_name'>FAQ</span>
          </Link>
          <span className='tooltip'>FAQ</span>
        </li>
        <li>
          <Link
            to='/help-center'
            className={location.pathname === '/help-center' ? 'activePath' : ''}
          >
            <i className='bx bx-info-circle'></i>
            <span className='links_name'>Help Center</span>
          </Link>
          <span className='tooltip'>Help Center</span>
        </li>
        <li>
          <Link
            to='/user-setting'
            className={
              location.pathname === '/user-setting' ? 'activePath' : ''
            }
          >
            <i className='bx bx-cog'></i>
            <span className='links_name'>User Setting</span>
          </Link>
          <span className='tooltip'>User Setting</span>
        </li> */}
      </ul>
      <div className='profile_content'>
        <div className='profile'>
          <div className='profile_details'>
            <img src={profilePicture} alt='' />
            <div className='name_job'>
              <div className='name'>{domain?.domain}</div>
              {/* <div className='job'>{domain?.companyName}</div> */}
            </div>
          </div>
          <i onClick={handleLogout} className='bx bx-log-out' id='log_out'></i>
        </div>
      </div>
    </div>
  )
}
