import { AppRouter, UserRouter, AdminRouter, CrmRouter } from '../routers'
import { Bounce } from 'react-toastify'

export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
  transition: Bounce,
}

export const APPS = [
  {
    subdomain: 'www',
    app: AppRouter,
    main: true,
  },
  {
    subdomain: 'app',
    app: UserRouter,
    main: false,
  },
  {
    subdomain: 'admin',
    app: AdminRouter,
    main: false,
  },
  {
    subdomain: 'crm',
    app: CrmRouter,
    main: false,
  },
]
