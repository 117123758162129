import useWindowDimensions from '../../utils/helpers'
import { Link } from 'react-router-dom'
import promotional from '../../assets/promotional.png'

export default function RegisterPage() {
  const { width } = useWindowDimensions()
  return (
    <div className='text-white w-screen h-screen bg-gradient-to-tr from-registerPageBgColorFrom to-registerPageBgColorTo'>
      {/* wrapper 2 box */}
      <div className=' w-full h-screen flex items-center justify-center'>
        <div className='w-full max-w-lg flex flex-col justify-center items-center md:mt-0 sm:max-w-md xl:p-0'>
          <p className='mb-2 text-4xl'>Thanks for registration</p>
          <p className='text-lg text-center'>
            Welcome to Maxim Digital!
            <br />
            Your link activation has been sent to your email
          </p>
          <p className='mt-9 mb-3 text-lg font-bold'>What is next?</p>
          <div className='flex items-center space-x-2.5 rtl:space-x-reverse px-20'>
            <span className='flex items-center justify-center w-8 h-8 border rounded-full shrink-0'>
              1
            </span>
            <span>
              <p className='font-medium leading-tight'>
                Please confirm your new account by clicking on the link
                submitted
              </p>
            </span>
          </div>
          <div className='mt-4 flex items-center space-x-2.5 rtl:space-x-reverse px-20'>
            <span className='flex items-center justify-center w-8 h-8 border rounded-full shrink-0'>
              2
            </span>
            <span>
              <p className='font-medium leading-tight'>
                Go to{' '}
                <Link
                  to={`${process.env.REACT_APP_SCHEME}://app.${process.env.REACT_APP_DOMAIN}/login`}
                  style={{ color: 'red' }}
                >
                  Login
                </Link>{' '}
                then enter your new account
              </p>
            </span>
          </div>
        </div>
        {width > 1010 && (
          <div className='w-full max-w-lg text-white'>
            <div className='text-center font-semibold text-lg mb-8'>
              Get a Stable Advertising Account now ! <br />
              So you can focus on developing your business
            </div>
            <div className='flex justify-center items-center'>
              <img alt='promotional' width={380} src={promotional} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
