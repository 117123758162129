import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import '../Main.css'
import SideBar from './global/SideBar'
import ConversionTable from './table/ConversionTable'
import { fetchConversions, fetchDomain } from '../../redux/crmAuthSlice'

export default function HomePage() {
  const { conversions, domain } = useSelector((state) => state.crmAuth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchConversions())
    dispatch(fetchDomain())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <SideBar domain={domain} />
      <div className='home_content poppins bg-[#f1faff]'>
        <div className='mx-12'>
          <ConversionTable conversions={conversions} />
        </div>
      </div>
    </>
  )
}
