import '../Main.css'
import DeductAccountTable from './table/DeductAccountTable'
import SideBar from './global/SideBar'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, Fragment, useState } from 'react'
import {
  fetchTransactionUserData,
  updateDeductAccountTransaction,
} from '../../redux/adminAuthSlice'
import { Dialog, Transition } from '@headlessui/react'

export default function DeductAccountPage() {
  const [
    handleRejectDeductAccountDialogOpen,
    setHandleRejectDeductAccountDialogOpen,
  ] = useState(false)
  const [
    handleDoneDeductAccountDialogOpen,
    setHandleDoneDeductAccountDialogOpen,
  ] = useState(false)
  const [selectedTransactionUserDatum, setSelectedTransactionUserDatum] =
    useState({})
  const dispatch = useDispatch()
  const { admin, transactionUserData } = useSelector((state) => state.adminAuth)

  const handleOpenDoneDeductAccountDialog = (transaction) => {
    setSelectedTransactionUserDatum(transaction)
    setHandleDoneDeductAccountDialogOpen(true)
  }

  const handleOpenRejectDialog = (transaction) => {
    setSelectedTransactionUserDatum(transaction)
    setHandleRejectDeductAccountDialogOpen(true)
  }

  async function sendRejectRequest() {
    let json = {}
    json.transactionId = selectedTransactionUserDatum._id
    json.newStatus = 'Rejected (Deduct Amount > Current Balance)'

    setHandleRejectDeductAccountDialogOpen(false)
    dispatch(updateDeductAccountTransaction(json)).then(() =>
      dispatch(fetchTransactionUserData())
    )
  }

  async function submitDoneDeduct() {
    let json = {}
    json.transactionId = selectedTransactionUserDatum._id
    json.newStatus = 'Done'

    setHandleDoneDeductAccountDialogOpen(false)
    dispatch(updateDeductAccountTransaction(json)).then(() =>
      dispatch(fetchTransactionUserData())
    )
  }

  useEffect(() => {
    dispatch(fetchTransactionUserData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar admin={admin} />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='ml-6 mt-6 w-full'>
          <div className='text-xl font-bold'>Deduct Account</div>
        </section>
        <section className='ml-6 mt-4 w-11/12'>
          <DeductAccountTable
            transactionUserData={
              Array.isArray(transactionUserData) &&
              transactionUserData.filter(
                (transactionUserDatum) =>
                  transactionUserDatum.type === 'Deduct Account'
              )
            }
            openDeductAccountRejectDialog={handleOpenRejectDialog}
            openDoneDeductAccountDialog={handleOpenDoneDeductAccountDialog}
          />
          {/* reject dialog */}
          <Transition.Root
            show={handleRejectDeductAccountDialogOpen}
            as={Fragment}
          >
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setHandleRejectDeductAccountDialogOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={sendRejectRequest}
                        >
                          Reject (Deduct Amount is more than current account
                          balance)
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* reject dialog */}
          {/* done ad account deduction dialog */}
          <Transition.Root
            show={handleDoneDeductAccountDialogOpen}
            as={Fragment}
          >
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setHandleDoneDeductAccountDialogOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={submitDoneDeduct}
                        >
                          Confirm done ad account deduction
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* done ad account deduction dialog */}
        </section>
      </div>
    </>
  )
}
