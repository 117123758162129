import { Fragment, useEffect, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import '../Main.css'
import { Dialog, Transition } from '@headlessui/react'
import TimezoneSelect from 'react-timezone-select'
import SideBar from './global/SideBar'
import { useSelector } from 'react-redux'
import {
  fetchUser,
  fetchAccounts,
  createAccount,
  updateUser,
  topupAdAccount,
  deductAdAccount,
} from '../../redux/authSlice'
import { useDispatch } from 'react-redux'
import OverviewTable from './table/OverviewTable'

export default function HomePage() {
  const { user, accounts } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [hasCompanyName, setHasCompanyName] = useState(false)
  const [hasPhoneNumber, setHasPhoneNumber] = useState(false)
  const [hasCountryCode, setHasCountryCode] = useState(false)
  const [hasTelegram, setHasTelegram] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [telegram, setTelegram] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [rejectedReason, setRejectedReason] = useState('')
  const [selectedAccount, setSelectedAccount] = useState({})
  const [addFundAmount, setAddFundAmount] = useState()
  const [deductAccountAmount, setDeductAccountAmount] = useState()

  const [requiredInfoFormOpen, setRequiredInfoFormOpen] = useState(false)
  const [createAccountFormOpen, setCreateAccountFormOpen] = useState(false)
  const [rejectedReasonFormOpen, setRejectedReasonFormOpen] = useState(false)
  const [addFundFormOpen, setAddFundFormOpen] = useState(false)
  const [deductAccountFormOpen, setDeductAccountFormOpen] = useState(false)
  const [successfulPostAccountDialog, setSuccessfulPostAccountDialog] =
    useState(false)

  // CREATE AD ACCOUNT FORM DATA
  const [accountType, setAccountType] = useState('Meta')
  const [accountCurrency, setAccountCurrency] = useState('usdt')
  const [accountLinkLandingPage, setAccountLinkLandingPage] = useState('')

  const [linkFanpage, setLinkFanpage] = useState('')
  const [emailTiktokPersonalAccount, setEmailTiktokPersonalAccount] =
    useState('')
  const [businessCenterId, setBusinessCenterId] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [businessManagerId, setBusinessManagerId] = useState([''])
  const handleBusinessManagerIdChange = (index, event) => {
    const values = [...businessManagerId]
    values[index] = event.target.value
    setBusinessManagerId(values)
  }
  const handleAddBusinessManagerId = () => {
    if (businessManagerId.length === 5) return
    setBusinessManagerId([...businessManagerId, ''])
  }
  const handleRemoveBusinessManagerId = (index) => {
    if (businessManagerId.length === 1) return
    const values = [...businessManagerId]
    values.splice(index, 1)
    setBusinessManagerId(values)
  }

  const setMissingUserInfo = (user) => {
    setHasCompanyName(user.companyName)
    setHasPhoneNumber(user.phoneNumber)
    setHasCountryCode(user.countryCode)
    setHasTelegram(user.telegram)
  }

  const initCreateAdAccountFormData = () => {
    setAccountType('Meta')
    setAccountCurrency('usdt')
    setAccountLinkLandingPage('')
    setLinkFanpage('')
    setEmailTiktokPersonalAccount('')
    setBusinessCenterId('')
    setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    setBusinessManagerId([''])
  }

  const initTopupAdAccountFormData = () => {
    setSelectedAccount({})
    setAddFundAmount()
  }

  const initDeductAdAccountFormData = () => {
    setSelectedAccount({})
    setDeductAccountAmount()
  }

  const handleCreateAccount = (event) => {
    event.preventDefault()
    if (!accountType) return
    let json = {}
    json.type = accountType

    if (
      !accountCurrency ||
      !selectedTimezone ||
      !accountLinkLandingPage ||
      !user.phoneNumber ||
      !user.countryCode
    )
      return

    if (json.type === 'Meta')
      if (
        !linkFanpage ||
        (businessManagerId.length === 1 && businessManagerId[0] === '')
      )
        return
    if (json.type === 'Tiktok')
      if (!emailTiktokPersonalAccount || !businessCenterId) return

    json.currency = accountCurrency
    json.timezone = selectedTimezone
    json.linkLandingPage = accountLinkLandingPage
    json.countryCode = user.countryCode
    json.phoneNumber = user.phoneNumber
    json.telegram = user.telegram

    if (json.type === 'Meta') {
      json.linkFanpage = linkFanpage
      json.businessManagerId = businessManagerId
    }
    if (json.type === 'Tiktok') {
      json.emailTiktokPersonalAccount = emailTiktokPersonalAccount
      json.businessCenterId = businessCenterId
    }
    dispatch(createAccount(json)).then(() => {
      dispatch(fetchAccounts())
      setCreateAccountFormOpen(false)
      setSuccessfulPostAccountDialog(true)
      initCreateAdAccountFormData()
    })
  }

  const handleTopupAdAccount = (event) => {
    event.preventDefault()
    setAddFundAmount(Number(addFundAmount))
    if (isNaN(addFundAmount)) return
    if (addFundAmount < 10) return
    if (user.wallet[selectedAccount] < addFundAmount) return

    let json = {}
    json._id = selectedAccount._id
    json.currency = selectedAccount.currency
    json.addFundAmount = addFundAmount

    dispatch(topupAdAccount(json)).then(() => {
      setAddFundFormOpen(false)
      initTopupAdAccountFormData()
    })
  }

  const handleDeductAdAccount = (event) => {
    event.preventDefault()
    setDeductAccountAmount(Number(deductAccountAmount))
    if (isNaN(deductAccountAmount)) return
    if (deductAccountAmount < 10) return
    // if (user.wallet[selectedAccount] < deductAccountAmount) return

    let json = {}
    json._id = selectedAccount._id
    json.currency = selectedAccount.currency
    json.deductAccountAmount = deductAccountAmount

    dispatch(deductAdAccount(json)).then(() => {
      setDeductAccountFormOpen(false)
      initDeductAdAccountFormData()
    })
  }

  const handlePatchUser = (event) => {
    event.preventDefault()
    if (!hasCompanyName && !companyName) return
    if (!hasCountryCode && !countryCode) return
    if (!hasPhoneNumber && !phoneNumber) return
    let json = {}
    if (!hasCompanyName) json.companyName = companyName
    if (!hasCountryCode) json.countryCode = countryCode
    if (!hasPhoneNumber) json.phoneNumber = phoneNumber

    if (!hasTelegram) json.telegram = telegram
    const data = { userId: user._id, json }
    dispatch(updateUser(data)).then(() => {
      dispatch(fetchUser()).then(() => {
        setRequiredInfoFormOpen(false)
        setCreateAccountFormOpen(true)
        setMissingUserInfo(user)
      })
    })
  }

  const openForm = async (event) => {
    event.preventDefault()
    initCreateAdAccountFormData()
    if (!(user.countryCode && user.phoneNumber && user.companyName))
      setRequiredInfoFormOpen(true)
    else setCreateAccountFormOpen(true)
  }

  function handleOpenRejectedReasonDialog(rejectedReasonFromChild) {
    setRejectedReason(rejectedReasonFromChild)
    setRejectedReasonFormOpen(true)
  }

  function handleOpenAddFundDialog(account) {
    setSelectedAccount(account)
    setAddFundFormOpen(true)
  }

  function handleOpenReduceFundDialog(account) {
    setSelectedAccount(account)
    setDeductAccountFormOpen(true)
  }

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchAccounts())
    setMissingUserInfo(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar user={user} />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='flex'>
          <section className='ml-6 mt-6 w-1/4'>
            <div className='text-xl font-bold underline'>Total Ad Account</div>
            <div className='font-semibold'>
              <div>
                Meta{' - '}
                {Array.isArray(accounts) &&
                  accounts.filter((object) => object.type === 'Meta').length}
              </div>
              <div>
                Tiktok{' - '}
                {Array.isArray(accounts) &&
                  accounts.filter((object) => object.type === 'Tiktok').length}
              </div>
            </div>
          </section>
          <section className='ml-6 mt-6 w-full'>
            <div className='text-xl font-bold'>
              Wallet Balance - ${user?.wallet?.usdt}
            </div>
            <div className='font-semibold'>
              <div>
                Please ensure there is enough balance before add fund to ad
                account
              </div>
              <div>
                You can add wallet balance by navigating to Billing Page from
                the sidebar
              </div>
            </div>
          </section>
        </section>
        <section className='ml-6 mt-4 flex w-full'>
          <button
            onClick={openForm}
            type='button'
            className='inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            <PlusCircleIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            Create Ad Account
          </button>

          {/* if no company name and phone no */}
          <Transition.Root show={requiredInfoFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setRequiredInfoFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-base font-semibold leading-6 text-gray-900'
                          >
                            User Information
                          </Dialog.Title>
                          {!hasCompanyName && (
                            <>
                              <label className='block text-sm font-medium leading-6 text-gray-900'>
                                Company Name*
                              </label>
                              <div className='my-3'>
                                <input
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                  type='text'
                                  className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder='Maxim Digital'
                                />
                              </div>
                            </>
                          )}
                          {!hasPhoneNumber && (
                            <>
                              <label className='block text-sm font-medium leading-6 text-gray-900'>
                                Country Code* & Phone Number*
                              </label>
                              <div className='relative mt-2 rounded-md shadow-sm'>
                                <input
                                  onChange={(e) =>
                                    setCountryCode(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === 'e' ||
                                      e.key === '+' ||
                                      e.key === '-' ||
                                      e.key === '.'
                                    ) {
                                      e.preventDefault()
                                    }
                                  }}
                                  type='number'
                                  className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder='62'
                                />
                                <input
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === 'e' ||
                                      e.key === '+' ||
                                      e.key === '-' ||
                                      e.key === '.'
                                    ) {
                                      e.preventDefault()
                                    }
                                  }}
                                  type='number'
                                  className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder='12345678901'
                                />
                              </div>
                            </>
                          )}
                          {!hasTelegram && (
                            <>
                              <label className='mt-3 block text-sm font-medium leading-6 text-gray-900'>
                                Telegram Username
                              </label>
                              <div className='my-2'>
                                <input
                                  onChange={(e) => setTelegram(e.target.value)}
                                  type='text'
                                  className='mt-3 block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder='username'
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className='mt-4 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handlePatchUser}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* if no company name and phone no */}

          {/* create ad account form */}
          <Transition.Root show={createAccountFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setCreateAccountFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-base font-semibold leading-6 text-gray-900'
                          >
                            Get New Ad Account
                          </Dialog.Title>
                          <form>
                            <label className='mr-2 block text-sm font-medium leading-6 text-gray-900'>
                              Type
                            </label>
                            <select
                              onChange={(e) => setAccountType(e.target.value)}
                              className='h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            >
                              <option>Meta</option>
                              <option>Tiktok</option>
                            </select>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Currency
                            </label>
                            <select
                              onChange={(e) =>
                                setAccountCurrency(e.target.value)
                              }
                              className='h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            >
                              <option value='usdt'>USDT</option>
                            </select>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Timezone
                            </label>
                            <TimezoneSelect
                              value={selectedTimezone}
                              onChange={(e) => setSelectedTimezone(e.value)}
                            />
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Link Landing Page
                            </label>
                            <input
                              onChange={(e) =>
                                setAccountLinkLandingPage(e.target.value)
                              }
                              type='text'
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              placeholder='Website or App Store Link'
                            />
                            {accountType === 'Meta' && (
                              <>
                                <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                                  Link Fanpage
                                </label>
                                <input
                                  onChange={(e) =>
                                    setLinkFanpage(e.target.value)
                                  }
                                  type='text'
                                  className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder='Meta Page Link'
                                />
                                <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                                  Business Manager ID
                                </label>
                                {businessManagerId.map((input, index) => (
                                  <div
                                    key={index}
                                    className='flex items-center space-x-2'
                                  >
                                    <input
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === 'e' ||
                                          e.key === '+' ||
                                          e.key === '-' ||
                                          e.key === '.'
                                        ) {
                                          e.preventDefault()
                                        }
                                      }}
                                      onChange={(event) =>
                                        handleBusinessManagerIdChange(
                                          index,
                                          event
                                        )
                                      }
                                      type='number'
                                      value={businessManagerId[index]}
                                      className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                      placeholder='1234'
                                    />
                                    <button
                                      type='button'
                                      onClick={() =>
                                        handleRemoveBusinessManagerId(index)
                                      }
                                      className='rounded bg-red-500 px-1 py-1 text-white hover:bg-red-700'
                                    >
                                      Remove
                                    </button>
                                  </div>
                                ))}
                                <button
                                  type='button'
                                  onClick={handleAddBusinessManagerId}
                                  className='mt-2 rounded bg-green-600 px-4 py-1 text-white hover:bg-green-500'
                                >
                                  Add more ID
                                </button>
                              </>
                            )}
                            {accountType === 'Tiktok' && (
                              <>
                                <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                                  Email Tiktok Personal Account
                                </label>
                                <input
                                  onChange={(e) =>
                                    setEmailTiktokPersonalAccount(
                                      e.target.value
                                    )
                                  }
                                  type='email'
                                  className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder=''
                                />
                                <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                                  Business Center ID
                                </label>
                                <input
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === 'e' ||
                                      e.key === '+' ||
                                      e.key === '-' ||
                                      e.key === '.'
                                    ) {
                                      e.preventDefault()
                                    }
                                  }}
                                  onChange={(e) =>
                                    setBusinessCenterId(e.target.value)
                                  }
                                  type='number'
                                  className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                  placeholder=''
                                />
                              </>
                            )}
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Country Code & Phone Number
                            </label>
                            <div className='relative mt-2 rounded-md shadow-sm'>
                              <input
                                disabled
                                value={user?.countryCode}
                                type='number'
                                className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              />
                              <input
                                disabled
                                value={user?.phoneNumber}
                                type='number'
                                className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              />
                            </div>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Telegram Username
                            </label>
                            <input
                              value={user?.telegram}
                              disabled
                              type='text'
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              placeholder=''
                            />
                          </form>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleCreateAccount}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* create ad account form */}

          {/* successful post ad account dialog */}
          <Transition.Root show={successfulPostAccountDialog} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setSuccessfulPostAccountDialog}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-xl font-semibold leading-6 text-gray-900'
                          >
                            Thank you for your submission!
                          </Dialog.Title>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            Our team will review your data and contact you by
                            phone/telegram
                          </Dialog.Description>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            Make sure that your number is active. The result
                            will be posted on the dashboard and your email. You
                            will get the result within 1 - 2 working days
                          </Dialog.Description>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            Please wait, in case of rejection, kindly follow our
                            guidelines
                          </Dialog.Description>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* successful post ad account dialog */}

          {/* rejected reason dialog */}
          <Transition.Root show={rejectedReasonFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setRejectedReasonFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Description
                            as='h5'
                            className='mb-1 text-base font-medium leading-6 text-gray-900'
                          >
                            {rejectedReason}
                          </Dialog.Description>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* rejected reason dialog */}

          {/* add fund form */}
          <Transition.Root show={addFundFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setAddFundFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-base font-semibold leading-6 text-gray-900'
                          >
                            Add Fund
                          </Dialog.Title>
                          <div>Transfer balance from wallet to ad account</div>
                          <div>
                            Ad Account Name: {user?.companyCode}{' '}
                            {selectedAccount.currency} - {user?.companyName}
                          </div>
                          <div>Ad Account ID: {selectedAccount.id}</div>
                          <form>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Amount (min: 10)
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'e' ||
                                  e.key === '+' ||
                                  e.key === '-' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onChange={(e) => setAddFundAmount(e.target.value)}
                              value={addFundAmount}
                              type='number'
                              inputMode='numeric'
                              pattern='\d*'
                            ></input>
                          </form>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleTopupAdAccount}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* add fund form */}

          {/* reduce fund form */}
          <Transition.Root show={deductAccountFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setDeductAccountFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-base font-semibold leading-6 text-gray-900'
                          >
                            Reduce Fund
                          </Dialog.Title>
                          <div>Transfer balance from ad account to wallet</div>
                          <div>
                            Ad Account Name: {user?.companyCode}{' '}
                            {selectedAccount.currency} - {user?.companyName}
                          </div>
                          <div>Ad Account ID: {selectedAccount.id}</div>
                          <form>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Amount (min: 10)
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'e' ||
                                  e.key === '+' ||
                                  e.key === '-' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onChange={(e) =>
                                setDeductAccountAmount(e.target.value)
                              }
                              value={deductAccountAmount}
                              type='number'
                              inputMode='numeric'
                              pattern='\d*'
                            ></input>
                          </form>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleDeductAdAccount}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* reduce fund form */}
        </section>
        <section className='ml-6 mt-4 w-11/12'>
          <OverviewTable
            user={user}
            accounts={accounts}
            openRejectedReasonDialog={handleOpenRejectedReasonDialog}
            openAddFundDialog={handleOpenAddFundDialog}
            openReduceFundDialog={handleOpenReduceFundDialog}
          />
        </section>
      </div>
    </>
  )
}
