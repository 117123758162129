import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SideBar from './global/SideBar'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchUser, updateUserInfo } from '../../redux/authSlice'
import { toastErrorMessage, toastSuccessMessage } from '../../utils/helpers'
import '../Main.css'

export default function UserSettingPage() {
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [companyName, setCompanyName] = useState(user.companyName)
  const [name, setName] = useState(user.name)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [telegram, setTelegram] = useState(user.telegram)
  const [countryCode, setCountryCode] = useState(user.countryCode || '62')

  async function handleSubmit(event) {
    event.preventDefault()
    if (
      user.name === name &&
      user.phoneNumber === phoneNumber &&
      user.countryCode === countryCode &&
      user.telegram === telegram &&
      user.companyName === companyName
    ) {
      toastErrorMessage('Please edit before save')
      return
    }

    if (!name || !phoneNumber || !countryCode || !companyName) {
      toastErrorMessage('Please fill required info')
      return
    }

    const json = {
      phoneNumber,
      countryCode,
      telegram,
      companyName,
      name,
    }

    await axios
      .patch(`self-serve-ad/users/${user._id}`, json, {
        withCredentials: true,
      })
      .then(async function (response) {
        if (response.status === 200) {
          toastSuccessMessage('User info updated')
          dispatch(updateUserInfo(response.data))
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    dispatch(fetchUser())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ToastContainer />
      <SideBar user={user} />
      <div className='home_content poppins flex justify-center bg-[#f1faff]'>
        <section className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
          <div
            className='max-w-lg rounded-lg shadow shadow-blue-500 sm:max-w-md md:mt-0 xl:p-0'
            style={{ width: '600px' }}
          >
            <div className='space-y-4 p-6 sm:p-8 md:space-y-6'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
                Edit Profile
              </h1>
              <form
                className='mt-4 h-full md:mt-6'
                action='#'
                onSubmit={handleSubmit}
              >
                <div className='mb-4'>
                  <label className='mb-2 block text-sm font-medium text-gray-900'>
                    Name*
                  </label>
                  <input
                    placeholder='Please enter name'
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-gray-900 focus:ring-blue-500 sm:text-sm'
                  />
                </div>
                <div className='mb-4'>
                  <label className='mb-2 block text-sm font-medium text-gray-900'>
                    Register Company Name*
                  </label>
                  <input
                    placeholder='Please enter company name'
                    value={companyName}
                    type='text'
                    onChange={(e) => setCompanyName(e.target.value)}
                    className='focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-gray-900 focus:ring-blue-500 sm:text-sm'
                  />
                </div>
                <label className='block text-sm font-medium leading-6 text-gray-900'>
                  Country Code* & Phone Number*
                </label>
                <div className='relative mt-2'>
                  <input
                    onChange={(e) => setCountryCode(e.target.value)}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'e' ||
                        e.key === '+' ||
                        e.key === '-' ||
                        e.key === '.'
                      ) {
                        e.preventDefault()
                      }
                    }}
                    value={countryCode}
                    type='number'
                    className='focus:border-primary-600 mb-4 block w-full rounded-lg border border-gray-300 p-2.5 text-center leading-8 text-gray-900 placeholder:text-gray-400 focus:ring-blue-500 sm:text-sm sm:leading-6'
                    placeholder='62'
                  />
                  <input
                    placeholder='Please enter phone number'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'e' ||
                        e.key === '+' ||
                        e.key === '-' ||
                        e.key === '.'
                      ) {
                        e.preventDefault()
                      }
                    }}
                    type='number'
                    className='focus:border-primary-600 block w-full rounded-lg border border-gray-300 p-2.5 text-center leading-8 text-gray-900 placeholder:text-gray-400 focus:ring-blue-500 sm:text-sm sm:leading-6'
                  />
                </div>
                <div className='mb-4 mt-4'>
                  <label className='mb-2 block text-sm font-medium text-gray-900'>
                    Telegram
                  </label>
                  <input
                    value={telegram}
                    type='text'
                    onChange={(e) => setTelegram(e.target.value)}
                    className='focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-gray-900 focus:ring-blue-500 sm:text-sm'
                  />
                </div>
                <div className='mb-4'>
                  <label className='mb-2 block text-sm font-medium text-gray-900'>
                    Email*
                  </label>
                  <input
                    value={user.email}
                    disabled
                    className='block w-full rounded-lg border-gray-300 bg-white p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 sm:text-sm'
                  />
                </div>
                <button
                  type='submit'
                  className='mb-4 mt-4 w-full rounded-lg bg-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-600'
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
