import '../Main.css'
import DeductWalletTable from './table/DeductWalletTable'
import SideBar from './global/SideBar'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, Fragment, useState } from 'react'
import {
  fetchTransactionUserData,
  updateDeductWalletTransaction,
} from '../../redux/adminAuthSlice'
import { Dialog, Transition } from '@headlessui/react'

export default function DeductWalletPage() {
  const [transferInfoFormOpen, setTransferInfoFormOpen] = useState(false)
  const [editInfoFormOpen, setEditInfoFormOpen] = useState(false)
  const [hash, setHash] = useState('')
  const [transferDate, setTransferDate] = useState('')
  const [selectedTransactionUserDatum, setSelectedTransactionUserDatum] =
    useState({})
  const dispatch = useDispatch()
  const { admin, transactionUserData } = useSelector((state) => state.adminAuth)

  const handleDeductRequestFromChild = (datum) => {
    if (datum.status !== 'Process') return

    let json = {}
    json.status = 'Deducted Awaiting Transfer'
    json.transactionId = datum._id

    dispatch(updateDeductWalletTransaction(json)).then(() =>
      dispatch(fetchTransactionUserData())
    )
  }

  function initTransferInfoFormData() {
    setHash('')
    setTransferDate('')
  }

  function initEditInfoFormData(datum) {
    setHash(datum.hash)
    setTransferDate(datum.transferDate)
  }

  function handleSetTransferInfo(datum) {
    if (datum.status !== 'Deducted Awaiting Transfer') return
    initTransferInfoFormData()
    setSelectedTransactionUserDatum(datum)
    setTransferInfoFormOpen(true)
  }

  function handleOpenEditForm(datum) {
    if (datum.status !== 'Done') return
    initEditInfoFormData(datum)
    setSelectedTransactionUserDatum(datum)
    setEditInfoFormOpen(true)
  }

  function handleSubmitEditInfo() {
    if (selectedTransactionUserDatum.status !== 'Done') return
    if (!hash || !transferDate) return

    let json = {}
    json.hash = hash
    json.status = 'Edit Data'
    json.transferDate = transferDate
    json.transactionId = selectedTransactionUserDatum._id

    dispatch(updateDeductWalletTransaction(json)).then(() => {
      dispatch(fetchTransactionUserData())
      setEditInfoFormOpen(false)
    })
  }

  function handleSubmitTransactionInfo() {
    if (selectedTransactionUserDatum.status !== 'Deducted Awaiting Transfer')
      return
    if (!hash || !transferDate) return

    let json = {}
    json.hash = hash
    json.status = 'Done'
    json.transferDate = transferDate
    json.transactionId = selectedTransactionUserDatum._id

    dispatch(updateDeductWalletTransaction(json)).then(() => {
      dispatch(fetchTransactionUserData())
      setTransferInfoFormOpen(false)
    })
  }

  useEffect(() => {
    dispatch(fetchTransactionUserData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar admin={admin} />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='ml-6 mt-6 w-full'>
          <div className='text-xl font-bold'>Deduct Wallet</div>
        </section>
        <section className='ml-6 mt-4 w-11/12'>
          <DeductWalletTable
            transactionUserData={
              Array.isArray(transactionUserData) &&
              transactionUserData.filter(
                (transactionUserDatum) =>
                  transactionUserDatum.type === 'Deduct Wallet'
              )
            }
            deduct={handleDeductRequestFromChild}
            setTransferInfo={handleSetTransferInfo}
            openEditForm={handleOpenEditForm}
          />
          {/* set transfer info form */}
          <Transition.Root show={transferInfoFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setTransferInfoFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div className='mb-4 text-center'>
                        <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                          Transaction Hash
                        </label>
                        <input
                          className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                          onChange={(e) => setHash(e.target.value)}
                        ></input>
                        <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                          Transfer Date
                        </label>
                        <input
                          className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                          onChange={(e) => setTransferDate(e.target.value)}
                          placeholder='YYYY-MM-DD'
                        ></input>
                      </div>

                      <div className='mt-4 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleSubmitTransactionInfo}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* set transfer info form */}

          {/* edit data form */}
          <Transition.Root show={editInfoFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setEditInfoFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div className='mb-4 text-center'>
                        <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                          Transaction Hash
                        </label>
                        <input
                          className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                          onChange={(e) => setHash(e.target.value)}
                          value={hash}
                        ></input>
                        <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                          Transfer Date
                        </label>
                        <input
                          className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                          onChange={(e) => setTransferDate(e.target.value)}
                          value={transferDate}
                          placeholder='YYYY-MM-DD'
                        ></input>
                      </div>

                      <div className='mt-4 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleSubmitEditInfo}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* edit data form */}
        </section>
      </div>
    </>
  )
}
