import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import SideBar from './global/SideBar'
import '../Main.css'

export default function HelpCenterPage() {
  const { user } = useSelector((state) => state.auth)
  const ColorText = styled.div`
    background: linear-gradient(to right, #662d8c, #ed1e79);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `
  return (
    <>
      <SideBar user={user} />
      <div className='home_content bg-[#f1faff] poppins flex justify-center'>
        <section className='absolute top-1/4 text-center'>
          <ColorText className='mb-4 font-extrabold text-4xl'>
            Got Question ? Please Contact Us !
          </ColorText>
          <ColorText className='mb-2 font-bold text-3xl'>
            We are here to help
          </ColorText>
          <ColorText className='mb-1 font-semibold text-2xl'>
            Email: info@maximdigi.com
          </ColorText>
          <ColorText className='font-semibold text-2xl'>
            Telegram: @maximdigi
          </ColorText>
        </section>
      </div>
    </>
  )
}
