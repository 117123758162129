import { Fragment, useEffect, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import '../Main.css'
import { Dialog, Transition } from '@headlessui/react'
import SideBar from './global/SideBar'
import { useSelector } from 'react-redux'
import {
  fetchUser,
  topupWallet,
  deductWallet,
  fetchTransactions,
} from '../../redux/authSlice'
import { useDispatch } from 'react-redux'
import WalletTransactionHistoryTable from './table/WalletTransactionHistoryTable'
import { toastErrorMessage } from '../../utils/helpers'
import { ToastContainer } from 'react-toastify'

export default function BillingPage() {
  const { user, transactions } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [selectedTransactionHistoryView, setSelectedTransactionHistoryView] =
    useState('Wallet Balance')
  const [topupWalletFormOpen, setTopupWalletFormOpen] = useState(false)
  const [deductWalletFormOpen, setDeductWalletFormOpen] = useState(false)
  const [successfulPaymentDialog, setSuccessfulPaymentDialog] = useState(false)
  const [
    successfulDeductWalletRequestDialog,
    setSuccessfulDeductWalletRequestDialog,
  ] = useState(false)

  // TOPUP WALLET FORM DATA
  const [currency, setCurrency] = useState('usdt')
  const [transferNetwork, setTransferNetwork] = useState('TRC20 TRX (TRON)')
  const [amount, setAmount] = useState()
  const [transactionHash, setTransactionHash] = useState('')
  const [paymentProof, setPaymentProof] = useState('')
  const [walletAddress, setWalletAddress] = useState(
    'TL95NEa2hS2PD951A6xaavKJyEMLz6MWv6'
  )

  // DEDUCT WALLET FORM DATA
  const [deductCurrency, setDeductCurrency] = useState('usdt')
  const [deductTransferNetwork, setDeductTransferNetwork] =
    useState('TRC20 TRX (TRON)')
  const [deductAmount, setDeductAmount] = useState()
  const [deductWalletAddress, setDeductWalletAddress] = useState('')

  const initTopupWalletFormData = () => {
    setCurrency('usdt')
    setTransferNetwork('TRC20 TRX (TRON)')
    setWalletAddress('TL95NEa2hS2PD951A6xaavKJyEMLz6MWv6')
    setAmount()
    setTransactionHash('')
    setPaymentProof('')
  }

  const initDeductWalletFormData = () => {
    setDeductCurrency('usdt')
    setDeductTransferNetwork('TRC20 TRX (TRON)')
    setDeductWalletAddress('')
    setDeductAmount()
  }

  function handleOptionChange(event) {
    setSelectedTransactionHistoryView(event.target.value)
  }

  const handleTopupWallet = async (event) => {
    event.preventDefault()

    if (
      !currency ||
      !transferNetwork ||
      !walletAddress ||
      !amount ||
      !transactionHash ||
      !paymentProof
    )
      return

    if (Number(amount) < 100) return

    const setBase64PaymentProof = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
      })

    let json = {
      currency,
      transferNetwork,
      walletAddress,
      amount,
      transactionHash,
      paymentProof: await setBase64PaymentProof(paymentProof),
    }

    dispatch(topupWallet(json)).then(() => {
      dispatch(fetchTransactions())
      setTopupWalletFormOpen(false)
      setSuccessfulPaymentDialog(true)
      initTopupWalletFormData()
    })
  }

  const handleDeductWallet = async (event) => {
    event.preventDefault()

    if (
      !deductCurrency ||
      !deductTransferNetwork ||
      !deductWalletAddress ||
      !deductAmount
    )
      return

    if (Number(amount) < 100) return

    let json = {
      currency: deductCurrency,
      transferNetwork: deductTransferNetwork,
      walletAddress: deductWalletAddress,
      amount: deductAmount,
    }

    dispatch(deductWallet(json)).then((resp) => {
      if (resp.payload) {
        dispatch(fetchTransactions())
        setDeductWalletFormOpen(false)
        setSuccessfulDeductWalletRequestDialog(true)
      } else {
        toastErrorMessage('deduct amount or total amount in process > balance')
        return
      }
    })
  }

  const openTopupWalletForm = async (event) => {
    event.preventDefault()
    if (!user.companyName || !user.name) {
      toastErrorMessage(
        'Please ensure you have created at least 1 ad account before topup'
      )
      return
    }
    initTopupWalletFormData()
    setTopupWalletFormOpen(true)
  }

  const openDeductWalletForm = async (event) => {
    event.preventDefault()

    if (!user.companyName || !user.name) {
      toastErrorMessage(
        'Please ensure you have created at least 1 ad account before topup'
      )
      return
    }

    if (!user.wallet.usdt === 0) {
      toastErrorMessage('Cannot deduct wallet with 0 balance')
      return
    }

    initDeductWalletFormData()
    setDeductWalletFormOpen(true)
  }

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchTransactions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar user={user} />
      <ToastContainer />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='flex'>
          <section className='ml-6 mt-6 w-full'>
            <div className='text-xl font-bold'>
              Wallet Balance - ${user.wallet.usdt}
            </div>
          </section>
        </section>
        <section className='ml-6 mt-4 flex w-full'>
          <button
            onClick={openTopupWalletForm}
            type='button'
            className='mr-4 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            <PlusCircleIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            Topup Wallet
          </button>
          <button
            onClick={openDeductWalletForm}
            type='button'
            className='inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            <PlusCircleIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
            Deduct Wallet
          </button>

          {/* topup wallet form */}
          <Transition.Root show={topupWalletFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setTopupWalletFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 md:w-9/12'>
                      <div>
                        <div className='flex justify-center text-center'>
                          <form className='w-96'>
                            <label className='block text-sm font-medium leading-6 text-gray-900'>
                              Payment Method
                            </label>
                            <select
                              onChange={(e) => setCurrency(e.target.value)}
                              className='rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            >
                              <option value='usdt'>USDT</option>
                            </select>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Transfer Network
                            </label>
                            <select
                              onChange={(e) => {
                                const trfNetwork = e.target.value
                                setTransferNetwork(trfNetwork)
                                if (trfNetwork === 'TRC20 TRX (TRON)')
                                  setWalletAddress(
                                    'TL95NEa2hS2PD951A6xaavKJyEMLz6MWv6'
                                  )
                                if (trfNetwork === 'ERC20 Ethereum (ETH)')
                                  setWalletAddress(
                                    'TL95NEa2hS2PD951A6xaavKJyEMLz6MWv6'
                                  )
                              }}
                              className='rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-center text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            >
                              <option>TRC20 TRX (TRON)</option>
                              <option>ERC20 Ethereum (ETH)</option>
                            </select>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Wallet Address
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              readOnly
                              value={walletAddress}
                            ></input>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Amount (min: 100)
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'e' ||
                                  e.key === '+' ||
                                  e.key === '-' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onChange={(e) => setAmount(e.target.value)}
                              value={amount}
                              type='number'
                              inputMode='numeric'
                              pattern='\d*'
                            ></input>
                            <label className='mt-2 block text-left text-sm font-medium leading-6 text-gray-900'>
                              Transaction Hash / Transaction ID
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-left text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onChange={(e) =>
                                setTransactionHash(e.target.value)
                              }
                            ></input>
                            <label className='mt-2 block text-left text-sm font-medium leading-6 text-gray-900'>
                              Upload Payment Proof (JPEG/JPG/PNG)
                            </label>
                            <input
                              className='text-left'
                              type='file'
                              onChange={(e) =>
                                setPaymentProof(e.target.files[0])
                              }
                              accept='.jpeg, .jpg, .png'
                            />
                          </form>
                          <section className='ml-20 mt-14 w-2/6 text-left font-medium'>
                            <div className='mb-2 text-xl underline'>
                              Summary
                            </div>
                            <div className='mb-2'>
                              <p>Gate Fee (0%): 0 USD</p>
                              <p>
                                Service Fee (3%):{' '}
                                {amount ? Math.ceil(amount * 0.03) : 0} USD
                              </p>
                              <p>Tax (0%): 0 USD</p>
                            </div>
                            <div>
                              Total Amount:{' '}
                              <span className='text-orange-500'>
                                {amount ? Math.ceil(amount * 1.03) : 0} USD
                              </span>
                            </div>
                            <div className='mt-2 block text-sm font-medium leading-6 text-orange-500'>
                              Please make the transfer as specified in the total
                              amount value <br />
                              After making the transfer, please fill in the
                              transaction hash,
                              <br /> upload the payment proof, and click submit
                            </div>
                          </section>
                        </div>
                      </div>
                      <div className='mt-5 text-center sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-9/12 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleTopupWallet}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* topup wallet form */}

          {/* successful post topup wallet dialog */}
          <Transition.Root show={successfulPaymentDialog} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setSuccessfulPaymentDialog}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-3 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-xl font-semibold leading-6 text-gray-900'
                          >
                            Thank you for your payment!
                          </Dialog.Title>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            Our team will verify the payment, and update the
                            wallet balance accordingly
                          </Dialog.Description>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            Once it's done, an email will also be sent to keep
                            you updated
                          </Dialog.Description>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* successful post topup wallet dialog */}

          {/* deduct wallet form */}
          <Transition.Root show={deductWalletFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setDeductWalletFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 md:w-1/3'>
                      <div>
                        <div className='justify-center text-center'>
                          <form>
                            <div className='mb-2 text-base font-medium'>
                              After deducting the wallet balance, we will
                              transfer to your specified account information
                              below
                            </div>
                            <label className='block text-sm font-medium leading-6 text-gray-900'>
                              Payment Method
                            </label>
                            <select
                              onChange={(e) =>
                                setDeductCurrency(e.target.value)
                              }
                              className='rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            >
                              <option value='usdt'>USDT</option>
                            </select>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Transfer Network
                            </label>
                            <select
                              onChange={(e) =>
                                setDeductTransferNetwork(e.target.value)
                              }
                              className='rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-center text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            >
                              <option>TRC20 TRX (TRON)</option>
                              <option>ERC20 Ethereum (ETH)</option>
                            </select>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Wallet Address
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onChange={(e) =>
                                setDeductWalletAddress(e.target.value)
                              }
                            ></input>
                            <label className='mt-2 block text-sm font-medium leading-6 text-gray-900'>
                              Amount (min: 100)
                            </label>
                            <input
                              className='block w-full rounded-md border-0 py-1.5 text-center text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                              onKeyDown={(e) => {
                                if (
                                  e.key === 'e' ||
                                  e.key === '+' ||
                                  e.key === '-' ||
                                  e.key === '.'
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onChange={(e) => setDeductAmount(e.target.value)}
                              value={deductAmount}
                              type='number'
                              inputMode='numeric'
                              pattern='\d*'
                            ></input>
                          </form>
                        </div>
                      </div>
                      <div className='mt-4 text-center sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleDeductWallet}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* deduct wallet form */}

          {/* successful post deduct wallet dialog */}
          <Transition.Root
            show={successfulDeductWalletRequestDialog}
            as={Fragment}
          >
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setSuccessfulDeductWalletRequestDialog}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-3 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div>
                        <div className='text-center'>
                          <Dialog.Title
                            as='h3'
                            className='mb-2 text-xl font-semibold leading-6 text-gray-900'
                          >
                            Thank you for your submission!
                          </Dialog.Title>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            Our team will review your data. The result will be
                            posted on the dashboard and your email.
                          </Dialog.Description>
                          <Dialog.Description
                            as='h5'
                            className='mb-2 text-base font-medium leading-6 text-gray-900'
                          >
                            The process will last within 3-5 working days
                          </Dialog.Description>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* successful post deduct wallet dialog */}
        </section>
        <section className='ml-6 mt-4 flex w-full'>
          <div className='flex items-center'>
            <input
              type='radio'
              value='Wallet Balance'
              checked={selectedTransactionHistoryView === 'Wallet Balance'}
              onChange={handleOptionChange}
              className='h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-2 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-indigo-600'
            />
            <label
              htmlFor='default-radio-1'
              className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Wallet Balance Transaction History
            </label>
          </div>
          <div className='ml-4 flex items-center'>
            <input
              type='radio'
              value='Ad Account'
              checked={selectedTransactionHistoryView === 'Ad Account'}
              onChange={handleOptionChange}
              className='h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-2 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-indigo-600'
            />
            <label
              htmlFor='default-radio-2'
              className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
            >
              Ad Account Transaction History
            </label>
          </div>
        </section>

        <section className='ml-6 mt-4 w-11/12'>
          <WalletTransactionHistoryTable
            transactions={
              selectedTransactionHistoryView === 'Wallet Balance'
                ? transactions.filter(
                    (transaction) =>
                      transaction.type === 'Topup Wallet' ||
                      transaction.type === 'Deduct Wallet'
                  )
                : transactions.filter(
                    (transaction) =>
                      transaction.type === 'Topup Account' ||
                      transaction.type === 'Deduct Account'
                  )
            }
            selectedTransactionHistoryView={selectedTransactionHistoryView}
          />
        </section>
      </div>
    </>
  )
}
