import { Routes, Route, Navigate } from 'react-router-dom'
import DesktopPage from '../pages/landing-page/pages/desktop/Desktop'
import MobilePage from '../pages/landing-page/pages/mobile/Mobile'
import RegisterPage from '../pages/user/RegisterPage'
import VerificationPage from '../pages/user/VerificationPage'
import LoginPage from '../pages/user/LoginPage'
import AdminLoginPage from '../pages/admin/LoginPage'
import AdminHomePage from '../pages/admin/HomePage'
import CrmLoginPage from '../pages/crm/LoginPage'
import CrmHomePage from '../pages/crm/HomePage'
import CrmConfigPage from '../pages/crm/ConfigPage'
import AdminOpenAccountPage from '../pages/admin/OpenAccount'
import AdminTopupWalletPage from '../pages/admin/TopupWallet'
import AdminTopupAccountPage from '../pages/admin/TopupAccount'
import AdminDeductWalletPage from '../pages/admin/DeductWallet'
import AdminDeductAccountPage from '../pages/admin/DeductAccount'
import UserHomePage from '../pages/user/HomePage'
import UserBillingPage from '../pages/user/BillingPage'
import FaqPage from '../pages/user/FaqPage'
import HelpCenterPage from '../pages/user/HelpCenterPage'
import UserSettingPage from '../pages/user/UserSettingPage'
import { useSelector } from 'react-redux'
import useWindowDimensions from '../utils/helpers'

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          useWindowDimensions().width > 1200 ? <DesktopPage /> : <MobilePage />
        }
      />
    </Routes>
  )
}

export const UserRouter = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)

  return (
    <Routes>
      <Route
        path='/'
        element={isAuthenticated ? <UserHomePage /> : <Navigate to='/login' />}
      />
      <Route
        path='/billing'
        element={
          isAuthenticated ? <UserBillingPage /> : <Navigate to='/login' />
        }
      />
      <Route
        path='/faq'
        element={isAuthenticated ? <FaqPage /> : <Navigate to='/login' />}
      />
      <Route
        path='/help-center'
        element={
          isAuthenticated ? <HelpCenterPage /> : <Navigate to='/login' />
        }
      />
      <Route
        path='/user-setting'
        element={
          isAuthenticated ? <UserSettingPage /> : <Navigate to='/login' />
        }
      />
      <Route path='/register' element={<RegisterPage />} />
      <Route path='/Verification' element={<VerificationPage />} />
      <Route path='/login' element={<LoginPage />} />
    </Routes>
  )
}

export const AdminRouter = () => {
  const { isAdminAuthenticated } = useSelector((state) => state.adminAuth)

  return (
    <Routes>
      <Route
        path='/'
        element={
          isAdminAuthenticated ? <AdminHomePage /> : <Navigate to='/login' />
        }
      />
      <Route
        path='/open-account'
        element={
          isAdminAuthenticated ? (
            <AdminOpenAccountPage />
          ) : (
            <Navigate to='/login' />
          )
        }
      />
      <Route
        path='/topup-wallet'
        element={
          isAdminAuthenticated ? (
            <AdminTopupWalletPage />
          ) : (
            <Navigate to='/login' />
          )
        }
      />
      <Route
        path='/deduct-wallet'
        element={
          isAdminAuthenticated ? (
            <AdminDeductWalletPage />
          ) : (
            <Navigate to='/login' />
          )
        }
      />
      <Route
        path='/topup-account'
        element={
          isAdminAuthenticated ? (
            <AdminTopupAccountPage />
          ) : (
            <Navigate to='/login' />
          )
        }
      />
      <Route
        path='/deduct-account'
        element={
          isAdminAuthenticated ? (
            <AdminDeductAccountPage />
          ) : (
            <Navigate to='/login' />
          )
        }
      />
      <Route path='/login' element={<AdminLoginPage />} />
    </Routes>
  )
}

export const CrmRouter = () => {
  const { isCrmAuthenticated } = useSelector((state) => state.crmAuth)

  return (
    <Routes>
      <Route
        path='/'
        element={
          isCrmAuthenticated ? <CrmHomePage /> : <Navigate to='/login' />
        }
      />
      <Route
        path='/config'
        element={
          isCrmAuthenticated ? <CrmConfigPage /> : <Navigate to='/login' />
        }
      />
      <Route path='/login' element={<CrmLoginPage />} />
    </Routes>
  )
}
