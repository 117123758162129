import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage'
import authReducer from './authSlice'
import adminAuthReducer from './adminAuthSlice'
import crmAuthReducer from './crmAuthSlice'

const persistConfig = {
  key: 'root',
  storage: localforage,
}
const persistedReducer = persistReducer(persistConfig, authReducer)
const adminPersistedReducer = persistReducer(persistConfig, adminAuthReducer)
const crmPersistedReducer = persistReducer(persistConfig, crmAuthReducer)

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    adminAuth: adminPersistedReducer,
    crmAuth: crmPersistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_ENV !== 'development' ? false : true,
})
const persistor = persistStore(store)

export { store, persistor }
