// copas from https://codesandbox.io/p/sandbox/responsive-dashboard-sidebar-menu-gi7zl?file=%2Findex.html%3A6%2C13-6%2C66
import profilePicture from '../../../assets/profile-picture.svg'
import { useDispatch } from 'react-redux'
import { logout } from '../../../redux/adminAuthSlice'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function SideBar(props) {
  const location = useLocation()
  const admin = props.admin
  const [isActive, setIsActive] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const toggleClass = () => {
    setIsActive(!isActive)
  }

  const handleLogout = async (event) => {
    event.preventDefault()
    dispatch(logout())
    navigate('/login')
  }
  return (
    <div className={isActive ? 'poppins sidebar active' : 'poppins sidebar'}>
      <div className='logo_content'>
        <div className='logo'>
          <div className='logo_name'>MXD Platform</div>
        </div>
        {/* <i onClick={toggleClass} className='bx bx-menu' id='btn'></i> */}
      </div>
      <ul className='nav_list'>
        <li>
          <Link
            className={location.pathname === '/' ? 'activePath' : ''}
            to='/'
          >
            <i className='bx bx-grid-alt'></i>
            <span className='links_name'>Overview</span>
          </Link>
          <span className='tooltip'>Overview</span>
        </li>
        <li>
          <Link
            to='/open-account'
            className={
              location.pathname === '/open-account' ? 'activePath' : ''
            }
          >
            <i className='bx bx-lock-open-alt'></i>
            <span className='links_name'>Open Account</span>
          </Link>
          <span className='tooltip'>Open Account</span>
        </li>
        <li>
          <Link
            to='/topup-wallet'
            className={
              location.pathname === '/topup-wallet' ? 'activePath' : ''
            }
          >
            <i className='bx bx-wallet text-green-500'></i>
            <span className='links_name'>Topup Wallet</span>
          </Link>
          <span className='tooltip'>Topup Wallet</span>
        </li>
        <li>
          <Link
            to='/deduct-wallet'
            className={
              location.pathname === '/deduct-wallet' ? 'activePath' : ''
            }
          >
            <i className='bx bx-wallet text-red-500'></i>
            <span className='links_name'>Deduct Wallet</span>
          </Link>
          <span className='tooltip'>Deduct Wallet</span>
        </li>
        <li>
          <Link
            to='/topup-account'
            className={
              location.pathname === '/topup-account' ? 'activePath' : ''
            }
          >
            <i className='bx bx-dollar text-green-500'></i>
            <span className='links_name'>Topup Account</span>
          </Link>
          <span className='tooltip'>Topup Account</span>
        </li>
        <li>
          <Link
            to='/deduct-account'
            className={
              location.pathname === '/deduct-account' ? 'activePath' : ''
            }
          >
            <i className='bx bx-dollar text-red-500'></i>
            <span className='links_name'>Deduct Account</span>
          </Link>
          <span className='tooltip'>Deduct Account</span>
        </li>
      </ul>
      <div className='profile_content'>
        <div className='profile'>
          <div className='profile_details'>
            <img src={profilePicture} alt='' />
            <div className='name_job'>
              <div className='name'>{admin.email.split('@')[0]}</div>
            </div>
          </div>
          <i onClick={handleLogout} className='bx bx-log-out' id='log_out'></i>
        </div>
      </div>
    </div>
  )
}
