import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  isAuthenticated: false,
  user: null,
  accounts: [],
  transactions: [],
}

export const fetchUser = createAsyncThunk('fetchUser', async () => {
  return await axios
    .get('self-serve-ad/users', {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const updateUser = createAsyncThunk('updateUser', async (data) => {
  const { userId, json } = data
  return await axios
    .patch(`self-serve-ad/users/${userId}`, json, {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const fetchAccounts = createAsyncThunk('fetchAccounts', async () => {
  return await axios
    .get('self-serve-ad/accounts', {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const createAccount = createAsyncThunk('createAccount', async (json) => {
  return await axios
    .post('self-serve-ad/accounts', json, {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const topupWallet = createAsyncThunk('topupWallet', async (json) => {
  return await axios
    .post('self-serve-ad/transactions/wallet/topup', json, {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const deductWallet = createAsyncThunk('deductWallet', async (json) => {
  return await axios
    .post('self-serve-ad/transactions/wallet/deduct', json, {
      withCredentials: true,
    })
    .then(function (response) {
      if (response.status === 200) {
        return response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
})

export const topupAdAccount = createAsyncThunk(
  'topupAdAccount',
  async (json) => {
    return await axios
      .post('self-serve-ad/transactions/ad-account/topup', json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const deductAdAccount = createAsyncThunk(
  'deductAdAccount',
  async (json) => {
    return await axios
      .post('self-serve-ad/transactions/ad-account/deduct', json, {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

export const fetchTransactions = createAsyncThunk(
  'fetchTransactions',
  async () => {
    return await axios
      .get('self-serve-ad/transactions', {
        withCredentials: true,
      })
      .then(function (response) {
        if (response.status === 200) {
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload
    },
    updateUserInfo: (state, action) => {
      state.user = action.payload
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
      state.accounts = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload
      })
      .addCase(createAccount.fulfilled)
      .addCase(topupWallet.fulfilled)
      .addCase(deductWallet.fulfilled)
      .addCase(topupAdAccount.fulfilled)
      .addCase(deductAdAccount.fulfilled)
  },
})

export const { login, logout, updateUserInfo } = authSlice.actions
export const selectAuth = (state) => state.auth
export default authSlice.reducer
