import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/adminAuthSlice'

export default function LoginPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const handleLogin = async (event) => {
    event.preventDefault()
    await axios
      .post(
        'self-serve-ad/admins/login',
        {
          password,
          email,
        },
        { withCredentials: true }
      )
      .then(async function (response) {
        if (response.status === 200) {
          navigate('/')
          dispatch(login(response.data))
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className='h-screen w-screen bg-gradient-to-tr from-registerPageBgColorFrom to-registerPageBgColorTo'>
      <div className='flex h-screen w-full items-center justify-center'>
        {/* form */}
        <div className='w-full max-w-lg rounded-lg bg-[#F8F7F4] shadow sm:max-w-md md:mt-0 xl:p-0'>
          <div className='h-full p-6'>
            <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
              Login
            </h1>
            <form
              className='mt-4 h-full md:mt-6'
              action='#'
              onSubmit={handleLogin}
            >
              <div>
                <label
                  htmlFor='email'
                  className='mb-2 block text-sm font-medium text-gray-900'
                >
                  Email
                </label>
                <input
                  type='email'
                  name='email'
                  onChange={(e) => setEmail(e.target.value)}
                  id='email'
                  className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:ring-[#aa076b] sm:text-sm'
                  placeholder='Enter your email'
                  required={true}
                />
              </div>
              <div className='mb-7 mt-4'>
                <label
                  htmlFor='password'
                  className='mb-2 block text-sm font-medium text-gray-900'
                >
                  Password
                </label>
                <input
                  type='password'
                  name='password'
                  onChange={(e) => setPassword(e.target.value)}
                  id='password'
                  placeholder='••••••••'
                  className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:ring-[#aa076b] sm:text-sm'
                  required={true}
                />
              </div>
              <button
                type='submit'
                className='w-full rounded-lg bg-pink-800 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-4 focus:ring-pink-500'
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
