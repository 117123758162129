import * as React from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { ArrowUpDown, ChevronDown, MoreHorizontal } from 'lucide-react'

import { Button } from '../../../components/ui/button'
// import { Checkbox } from '../../../components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu'
import { Input } from '../../../components/ui/input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/ui/table'
import { toastErrorMessage } from '../../../utils/helpers'
import { ToastContainer } from 'react-toastify'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export default function OverviewTable({
  accounts,
  user,
  openRejectedReasonDialog,
  openAddFundDialog,
  openReduceFundDialog,
}) {
  function handleAddFund(account) {
    if (account.status !== 'Approved') {
      toastErrorMessage("Can't add fund for non 'Approved' account")
      return
    }
    if (user.wallet[account.currency] === 0) {
      toastErrorMessage('Please topup wallet before add fund')
      return
    }
    openAddFundDialog(account)
  }
  function handleReduceFund(account) {
    if (account.status !== 'Approved') {
      toastErrorMessage("Can't reduce fund for non 'Approved' account")
      return
    }
    openReduceFundDialog(account)
  }
  const columns = [
    // {
    //   id: 'select',
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && 'indeterminate')
    //       }
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label='Select all'
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label='Select row'
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false
    // },
    {
      accessorKey: 'type',
      header: 'Platform',
      cell: ({ row }) => <div>{row.getValue('type')}</div>,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
    },
    {
      accessorKey: 'id',
      header: 'ID',
      cell: ({ row }) => <div>{row.getValue('id')}</div>,
    },
    {
      accessorKey: 'status',
      header: ({ column }) => {
        return (
          <Button
            className='p-0'
            variant='ghost'
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Status
            <ArrowUpDown className='ml-2 h-4 w-4' />
          </Button>
        )
      },
      cell: ({ row }) =>
        row.getValue('status') === 'Rejected' ? (
          <div className='flex justify-center'>
            {row.getValue('status')}
            <button
              onClick={() =>
                openRejectedReasonDialog(row.original.rejectedReason)
              }
            >
              <InformationCircleIcon className='mx-1 h-6 w-6 stroke-red-400' />
            </button>
          </div>
        ) : (
          <div>
            {row.getValue('status') === 'Process'
              ? 'Review'
              : row.getValue('status')}
          </div>
        ),
    },
    {
      accessorKey: 'businessManagerId',
      header: 'Business Manager',
      cell: ({ row }) => (
        <div>
          {row.getValue('status') === 'Review'
            ? ''
            : row.getValue('businessManagerId').join(', ')}
        </div>
      ),
    },
    {
      accessorKey: 'businessCenterId',
      header: 'Business Center',
      cell: ({ row }) => (
        <div>
          {row.getValue('status') === 'Review'
            ? ''
            : row.getValue('businessCenterId')}
        </div>
      ),
    },
    {
      accessorKey: 'timezone',
      header: 'Timezone',
      cell: ({ row }) => <div>{row.getValue('timezone')}</div>,
    },
    {
      accessorKey: 'topup',
      header: 'Topup',
      cell: ({ row }) => <div>{row.getValue('topup')}</div>,
    },
    // {
    //   accessorKey: 'spending',
    //   header: 'Spending',
    //   cell: ({ row }) => <div>${row.getValue('spending')}</div>
    // },
    // {
    //   accessorKey: 'balance',
    //   header: 'Balance',
    //   cell: ({ row }) => <div>${row.getValue('balance')}</div>,
    // },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const account = row.original

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='ghost' className='h-8 w-8 p-0'>
                <span className='sr-only'>Open menu</span>
                <MoreHorizontal className='h-4 w-4' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem onClick={() => handleAddFund(account)}>
                Add Fund
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => handleReduceFund(account)}>
                Reduce Fund
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]
  const data = accounts
  const [sorting, setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return (
    <div className='w-full '>
      <ToastContainer />
      {/* filter input status */}
      <div className='flex items-center py-4'>
        <Input
          placeholder='Filter status...'
          value={table.getColumn('status')?.getFilterValue() ?? ''}
          onChange={(event) =>
            table.getColumn('status')?.setFilterValue(event.target.value)
          }
          className='max-w-sm'
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline' className='ml-auto'>
              Columns <ChevronDown className='ml-2 h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className='capitalize'
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {/* filter input status */}

      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* # rows selected, previous + next button */}
      <div className='flex items-center justify-end space-x-2 py-4'>
        {/* <div className='flex-1 text-sm text-muted-foreground'>
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div> */}
        <div className='space-x-2'>
          <Button
            variant='outline'
            size='sm'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant='outline'
            size='sm'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
      {/* # rows selected, previous + next button */}
    </div>
  )
}
