import SideBar from './global/SideBar'
import { useSelector } from 'react-redux'
import '../Main.css'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: 'How do I create new ad account?',
    answer:
      '1. If you’re new user, you must to register and login Mxd Platform, choose platform that you need to open (Meta/Tiktok), fill the information, and submit. 2. If you’re existing user, login and click “create ad account” on overview dashboard, fill the information, and submit',
  },
  {
    question:
      'How long does the review process for opening a new account will take?',
    answer:
      'We will process the data that you submitted in approximately 1-2 working days. After that there will be a confirmation on the dashboard and email regarding the approve or reject status',
  },
  {
    question: 'Why was my account opening application rejected?',
    answer:
      "There are several possibilities, one of them, the product link that you submitted doesn’t meet the requirements of Meta/Tiktok, But don't worry, there will be detailed information in the email and dashboard on the reasons why your application was rejected",
  },
  {
    question: "Why after opening an account, I can't do the top up?",
    answer:
      'There are several possibilities, one of them, that your wallet balance is empty, immediately top up your wallet balance and add funds to your ad account afterwards, or if there is something other than an empty wallet balance, please contact us via email/telegram',
  },
  {
    question:
      'If I want to open a meta account, how many Business Managers ID can be added?',
    answer:
      'There is no limit on adding a Business Manager ID to an ad account, enter it according to your needs',
  },
  {
    question:
      'Can I change Business manager ID or Business center after I have an account?',
    answer:
      'Please contact us via email/telegram than inform that you need to change',
  },
  {
    question: 'Why my ad account get suspended?',
    answer:
      'Maybe the products/services you promoted are considered violating the Meta/Tiktok policy',
  },
  {
    question: 'How do I appeal my suspended ad account?',
    answer:
      'Please send us your ad account ID and we will review your products/services also your campaigns, If your concern is right, we will help you appeal with Meta/TikTok',
  },
  {
    question: 'What payments are available to top up your wallet?',
    answer:
      'Currently, only USDT that available, if you wish to use another payment method, please contact us via email/telegram',
  },
  {
    question:
      'How long will it take for the wallet balance to update after making a payment?',
    answer: 'Normally it only takes a few minutes to pay with USDT',
  },
  {
    question: 'Why I can’t upload payment proof?',
    answer: 'Please contact us via email/telegram immediately',
  },
  {
    question: 'Is there a minimum amount to top up wallet balance?',
    answer:
      'Yes, the minimum topup that can be done is $100, less than this nominal, there will be a notification to correct the nominal',
  },
  {
    question: 'How much fee to open an account on the MXD Platform?',
    answer: 'Service Fee 3% for every payment',
  },
  {
    question:
      'Do I have to top up my wallet before I add funds to my ad account?',
    answer:
      'Yes, you have to top up your wallet first, then add funds to the ad account you have chosen',
  },
  {
    question: 'How to top up wallet balance?',
    answer:
      'If you pay with USDT, Go to Billing dashboard, click “Topup Wallet” button , choose currency, enter amount, click continue to payment, do the payment, enter transaction id, upload payment proof and click Submit',
  },
  {
    question: 'What is the minimum nominal add fund account?',
    answer: 'Minimum $10 per add fund transaction',
  },
  {
    question: 'Can I do transfer balance from ad account to wallet (withdraw)?',
    answer:
      'Yes, you can reduce funds, or transfer balance from ad account to wallet balance, with a minimum transfer of $10',
  },
  {
    question: 'Can I refund a wallet balance?',
    answer:
      'Yes can with term, please click “Deduct wallet” enter your detail of payment and also amount of deduct. We will process your request 5-10 days',
  },
  {
    question: 'Can I add more than one authorized person to Mxd Platform?',
    answer:
      'No, right now, only 1 user, maybe in future we can add this feature',
  },
]

export default function FaqPage() {
  const { user } = useSelector((state) => state.auth)

  return (
    <>
      <SideBar user={user} />
      <div className='poppins ml-16 bg-[#f1faff]'>
        <div>
          <div className='mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40'>
            <div className='mx-auto max-w-4xl divide-y divide-gray-900/10'>
              <h2 className='text-2xl font-extrabold leading-10 tracking-tight text-gray-900'>
                Frequently asked questions
              </h2>
              <dl className='mt-10 space-y-6 divide-y divide-gray-900/10'>
                {faqs.map((faq) => (
                  <Disclosure as='div' key={faq.question} className='pt-4'>
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className='flex w-full items-start justify-between text-left text-gray-900'>
                            <span className='text-base font-bold leading-7'>
                              {faq.question}
                            </span>
                            <span className='ml-6 flex h-7 items-center'>
                              {open ? (
                                <MinusSmallIcon
                                  className='h-6 w-6'
                                  aria-hidden='true'
                                />
                              ) : (
                                <PlusSmallIcon
                                  className='h-6 w-6'
                                  aria-hidden='true'
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                          <p className='text-base font-semibold leading-7 text-gray-600'>
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
