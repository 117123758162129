import '../Main.css'
import TopupWalletTable from './table/TopupWalletTable'
import SideBar from './global/SideBar'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, Fragment, useState } from 'react'
import {
  fetchTransactionUserData,
  updateTopupWalletTransaction,
} from '../../redux/adminAuthSlice'
import { Dialog, Transition } from '@headlessui/react'

export default function TopupWalletPage() {
  const [editStatusFormOpen, setEditStatusFormOpen] = useState(false)
  const [statusChangedTo, setStatusChangedTo] = useState('Process')
  const [currentStatus, setCurrentStatus] = useState('')
  const [paymentProof, setPaymentProof] = useState('')
  const [paymentProofFormOpen, setPaymentProofFormOpen] = useState(false)
  const [selectedTransactionUserDatum, setSelectedTransactionUserDatum] =
    useState({})
  const dispatch = useDispatch()
  const { admin, transactionUserData } = useSelector((state) => state.adminAuth)

  const handleTransactionUserDatumFromChild = (datum) => {
    setStatusChangedTo('Process')
    setCurrentStatus(datum.status)
    setSelectedTransactionUserDatum(datum)
    setEditStatusFormOpen(true)
  }

  function handleOpenPaymentProofDialog(paymentProofFromChild) {
    setPaymentProof(paymentProofFromChild)
    setPaymentProofFormOpen(true)
  }

  async function handleEditStatus() {
    if (currentStatus === statusChangedTo) return
    if (currentStatus === 'Pending' && statusChangedTo === 'Process') return
    if (
      currentStatus === 'Done' &&
      (statusChangedTo === 'Process' || statusChangedTo === 'Pending')
    )
      return

    let json = {}
    json.status = statusChangedTo
    json.transactionId = selectedTransactionUserDatum._id

    setEditStatusFormOpen(false)
    dispatch(updateTopupWalletTransaction(json)).then(() =>
      dispatch(fetchTransactionUserData())
    )
  }

  useEffect(() => {
    dispatch(fetchTransactionUserData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SideBar admin={admin} />
      <div className='home_content poppins bg-[#f1faff]'>
        <section className='ml-6 mt-6 w-full'>
          <div className='text-xl font-bold'>Topup Wallet</div>
        </section>
        <section className='ml-6 mt-4 w-11/12'>
          <TopupWalletTable
            openPaymentProofDialog={handleOpenPaymentProofDialog}
            transactionUserData={
              Array.isArray(transactionUserData) &&
              transactionUserData.filter(
                (transactionUserDatum) =>
                  transactionUserDatum.type === 'Topup Wallet'
              )
            }
            sendDataToParent={handleTransactionUserDatumFromChild}
          />
          <Transition.Root show={editStatusFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setEditStatusFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                      <div className='mb-4 text-center'>
                        <select
                          onChange={(e) => setStatusChangedTo(e.target.value)}
                          className='h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                        >
                          <option>Process</option>
                          <option>Pending</option>
                          <option>Done</option>
                        </select>
                      </div>

                      <div className='mt-4 sm:mt-6'>
                        <button
                          type='button'
                          className='inline-flex w-16 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                          onClick={handleEditStatus}
                        >
                          Submit
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* payment proof dialog */}
          <Transition.Root show={paymentProofFormOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-10'
              onClose={setPaymentProofFormOpen}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:p-6'>
                      <div>
                        <div className='flex items-center justify-center'>
                          <img src={paymentProof} alt='Payment Proof' />
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/* payment proof dialog */}
        </section>
      </div>
    </>
  )
}
